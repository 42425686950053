import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/tsuto/webapp/pcrecommend/src/components/layout.js";
import { Link, graphql } from "gatsby";
import HikakuTable from "components/hikakuTableLoadable";
import AccentBox from "components/accentBox";
import Accordion from 'components/accordion';
import Image from "components/image";
import Socials from "components/socials";
import TOC from "components/toc";
import List from "components/list";
import BasicTable from "components/basicTable";
import BenchMark from 'components/cpu/benchmark';
import IntelMatsubi from 'components/cpu/intel_matsubi';
import HowtoFilter from 'components/cpu/howtofilter';
import TableDescription from 'components/cpu/table_description';
import PassMark from 'components/cpu/passmark';
export const query = graphql`
  {
    allMdx(filter: {slug: {eq: "cpu/core-i7"}}) {
      nodes {
        headings {
          depth
          value
        }
      }
    },
    cover: file(relativePath: { eq: "cover/cpu/corei7.png"}) { ...eyecatchImg },
    alphabet: file(relativePath: { eq: "cpu/alphabet.png"}) { ...normalImg },
    allCoreIJson {
      nodes {
        name
        pm
        pms
        year
        clock
        core
        thread
        tdp
        price
        cospa
      }
    },
  }
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1>{`Core i7 比較表 | 世代や用途ごとにCore i7の性能を比較`}</h1>
    <time itemProp="datePublished" dateTime="Sat Apr 05 2025 03:50:22 GMT+0900 (日本標準時)">更新日:2025年4月5日</time>
    <Socials {...props} mdxType="Socials" />
    <Image {...props} name="cover" alt="Core i7 比較" mdxType="Image" />
    <p>{`Core i7(新世代のCore Ultra 7も含む)はIntelのCoreシリーズではCore i9に次いで性能が高いハイエンドなCPUである。メインのマシンとしての活用が期待される。`}</p>
    <p>{`ただし、コストパフォーマンスではCore i5に劣るため、Core i5からCore i7へは確かに性能は大きく上がるが、コストは更に増加するというハイエンドモデルの宿命たる性質もしっかりと持つ。`}</p>
    <p>{`世代や用途によっても性能は大きく異なるので、本当にCore i7にすべきなのか？Core i5で十分ではないのか？などを本記事と性能比較表を参考にしつつ検討していただきたい。`}</p>
    <p>{`尚、Coreシリーズについての概要を知りたい人は`}<Link to="/cpu/core-i/" mdxType="Link">{`Coreシリーズ比較表`}</Link>{`へ、またAMDのCPU RyzenやCeleronなど全てのCPUとの比較は本家`}<Link to="/cpu/" mdxType="Link">{`CPU性能比較表`}</Link>{`で確認して欲しい。`}</p>
    <TOC {...props} mdxType="TOC" />
    <h2 {...{
      "id": "Core-i7の用途別性能差-遅いCore-i7もある",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#Core-i7%E3%81%AE%E7%94%A8%E9%80%94%E5%88%A5%E6%80%A7%E8%83%BD%E5%B7%AE-%E9%81%85%E3%81%84Core-i7%E3%82%82%E3%81%82%E3%82%8B",
        "aria-label": "Core i7の用途別性能差 遅いCore i7もある permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Core i7の用途別性能差　遅いCore i7もある`}</h2>
    <Image {...props} name="alphabet" alt="CPUの末尾の文字を理解する" mdxType="Image" />
    <p>{`基本的にCPUは消費電力が大きいほど性能が高くなる。Core i7ではデスクトップ向けの125Wのオーバークロック版のCPUからノートの10Wを切る超消費電力版までフルラインナップがされている。`}</p>
    <p>{`Core i3、Core i5と同様であるが、その差は非常に大きく、`}<strong parentName="p">{`低消費電力のCore i7のYシリーズは8世代前、すなわち8年前に発売された通常版のCore i7に敵わない`}</strong>{`というベンチマーク結果も出ている。`}</p>
    <p>{`あくまでCore iシリーズのナンバリングは、末尾の文字が同じ場合での序列を意味しており、CPUの絶対的な速さの基準でないことは理解すべきである。`}</p>
    <h2 {...{
      "id": "Core-i7はゲーミングが一番の選択理由だがそれ以外も",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#Core-i7%E3%81%AF%E3%82%B2%E3%83%BC%E3%83%9F%E3%83%B3%E3%82%B0%E3%81%8C%E4%B8%80%E7%95%AA%E3%81%AE%E9%81%B8%E6%8A%9E%E7%90%86%E7%94%B1%E3%81%A0%E3%81%8C%E3%81%9D%E3%82%8C%E4%BB%A5%E5%A4%96%E3%82%82",
        "aria-label": "Core i7はゲーミングが一番の選択理由だがそれ以外も permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Core i7はゲーミングが一番の選択理由だがそれ以外も`}</h2>
    <p>{`ネットショッピングやYoutubeを快適に楽しみたいのでCore i7を選択しました、という人は流石にITエンジニアだらけの私の周りでも聞かない。`}</p>
    <p>{`Core i7を選択する理由として一番多いのは、高度なGPUと組み合わせてゲームで高いフレームレートを出すことな気がするが、他にも恩恵が大きいものとして`}</p>
    <List mdxType="List">
  <li>4K動画の編集やレンダリング、エンコードなどの高速化</li>
  <li>プログラミングのビルド時間の短縮</li>
  <li>仕事での生産性の向上</li>
    </List>
    <p>{`あたりがある。すでに待ち時間が定常的に発生する作業があり、そこを改善したいという時にCore i7を選ぶことは賢い投資方法である。`}</p>
    <p>{`ノートパソコンにおいてはCore i7のスペックもデスクトップほど高くはないので、軽めの作業であっても体感で分かるか分からない程度には動作速度が改善する。`}</p>
    <p>{`テレワークなどで１日８時間パソコンをする人であるならば、多少価格が高くてもチリツモで生産性をあげ、コストを回収できるだろう。`}</p>
    <h2 {...{
      "id": "Core-i7とCore-i5で迷った場合に考えたいこと",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#Core-i7%E3%81%A8Core-i5%E3%81%A7%E8%BF%B7%E3%81%A3%E3%81%9F%E5%A0%B4%E5%90%88%E3%81%AB%E8%80%83%E3%81%88%E3%81%9F%E3%81%84%E3%81%93%E3%81%A8",
        "aria-label": "Core i7とCore i5で迷った場合に考えたいこと permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Core i7とCore i5で迷った場合に考えたいこと`}</h2>
    <p>{`Core i7にすると性能は高くなるものの、Core i3からCore i5に変更した時と比べると、Core i5からCore i7へ変更したときは価格の割に性能の上がり幅が低くメリットが少ない。`}</p>
    <p>{`このためボリュームゾーンとして売れているものはCore i7ではなくCore i5である。このコスト差を回収できるかが一つポイントである。`}</p>
    <p>{`予算に余裕があることが前提であるが、`}<strong parentName="p">{`CPUを高速にすることで既に抱えている遅いと感じる問題を解決できるか？`}</strong>{`、`}<strong parentName="p">{`１日にパソコンを使用する時間が十分に長いか？`}</strong>{`などがポイントとなってくるだろう。`}</p>
    <h2 {...{
      "id": "Intel-Core-i7-性能比較表",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#Intel-Core-i7-%E6%80%A7%E8%83%BD%E6%AF%94%E8%BC%83%E8%A1%A8",
        "aria-label": "Intel Core i7 性能比較表 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Intel Core i7 性能比較表`}</h2>
    <p>{`それではCore i7 CPUの様々な世代、用途における性能比較表を示す。Core i5や他のCPUと比較したい場合はチェックボックスを利用して欲しい。`}</p>
    <p>{`ベンチマークとして総合的な能力を計測するPassMarkの総合スコアPassMark(M)とシングルコアの性能を測るPassMark(S)を掲載している。`}</p>
    <p><strong parentName="p"><em parentName="strong">{`基本的にはPassMark(M)を下記目安とともに参考にすれば良い`}</em></strong>{`が、ゲーミング性能を気にする場合はシングルコア性能も参考にしたい。`}</p>
    <PassMark mdxType="PassMark" />
    <p>{`その他、表の項目説明等はまとめておくので必要に応じて参考にして欲しい。`}</p>
    <BenchMark mdxType="BenchMark" />
    <TableDescription mdxType="TableDescription" />
    <Accordion title="Intel CPU末尾文字の意味一覧" mdxType="Accordion">
      <IntelMatsubi mdxType="IntelMatsubi" />
    </Accordion>
    <HowtoFilter mdxType="HowtoFilter" />
    <HikakuTable {...props} checkboxes={['corei', 'notedesk']} cpuData={props.data.allCoreIJson.nodes} checkStatus={{
      coreseven: true
    }} mdxType="HikakuTable" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      